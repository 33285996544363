
export enum OccupationSection {
    Leader = "Leader",
    Collaborators = "Collaborators",
    HDRStudents = "HDRStudents",
    ExternalResearchers = "ExternalResearchers",
    Alumni = "Alumni"
}


export interface MemberOutputDto {
    id: string;
    name: string;
    occupation: OccupationSection;
    selected: boolean;
    profileImage?: string;
    occupationSub?: string;
    school?: string;
    university?: string;
    orderNumber: number;
    email?: string;
    linkedIn?: string;
    homepage?: string;
    github?: string;
    scholar?: string;
}


export const allMembers: MemberOutputDto[] = [
    // Leader
    {
        id: "l-1",
        selected: true,
        name: "A/Prof. Chun Ouyang",
        occupation: OccupationSection.Leader,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1620301124/rhju6s6uoldcnxmw7c0o.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/ChunOuyang.jpg",
        occupationSub: "",
        school: "School of Information Systems",
        university: "QUT",
        orderNumber: 1,
        email: "c.ouyang@qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/chunouyang",
        homepage: "https://www.qut.edu.au/about/our-people/academic-profiles/c.ouyang",
        github: "",
        scholar: "https://scholar.google.com.au/citations?user=aQqD_CQAAAAJ",
    },
    {
        id: "l-2",
        selected: true,
        name: "A/Prof. Catarina Moreira",
        occupation: OccupationSection.Leader,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535125/vtyukjmxpoy2afzfpzfj.png",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Catarina.png",
        occupationSub: "",
        school: "Data Science Institute",
        university: "University of Technology Sydney",
        orderNumber: 2,
        email: "catarina.pintomoreira@qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/catarinapmoreira",
        homepage: "https://cmoreira.eu",
        github: "",
        scholar: "https://scholar.google.com/citations?user=nThsEsMAAAAJ",
    },

    // Collaborators
    {
        id: "c-1",
        selected: true,
        name: "Dr. Renuka Sindhgatta",
        occupation: OccupationSection.Collaborators,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1621573588/begjin9xq99zcs3m89je.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Renuka.jpg",
        occupationSub: "",
        university: "Bangalore, India",
        school: "IBM Research - AI",
        orderNumber: 1,
        email: "renuka.sr@ibm.com",
        scholar: "https://scholar.google.com/citations?user=P-YUol0AAAAJ",
        linkedIn: "https://www.linkedin.com/in/renuka-sindhgatta-9042242",
        github: "",
    },
    {
        id: "c-2",
        selected: true,
        name: "A/Prof. Yue Xu",
        occupation: OccupationSection.Collaborators,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1621573622/israd7mxvdxd5gbljesp.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/YueXu.jpg",
        occupationSub: "",
        school: "School of Computer Science",
        university: "QUT",
        orderNumber: 2,
        email: "yue.xu@qut.edu.au",
        homepage: "https://www.qut.edu.au/about/our-people/academic-profiles/yue.xu",
        scholar: "https://scholar.google.com/citations?user=dGr7W1kAAAAJ",
        linkedIn: "https://www.linkedin.com/in/yue-xu-7368b133"
    },
    {
        id: "c-3",
        selected: true,
        name: "Prof. Joaquim Jorge",
        occupation: OccupationSection.Collaborators,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535448/jvdkcyvgrqu5foyvxpd2.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Joaquim.jpg",
        occupationSub: "",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon",
        orderNumber: 4,
        email: "jorgej©tecnico.ulisboa.pt",
        homepage: "https://web.ist.utl.pt/jorgej/",
        scholar: "https://scholar.google.com/citations?user=RgiMdpAAAAAJ",
        linkedIn: "https://www.linkedin.com/in/jorgej",
    },
    {
        id: "c-4",
        selected: true,
        name: "Prof. Alistair Barros",
        occupation: OccupationSection.Collaborators,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633678145/Alistair-Barros_1_vkkwno.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Alistair.jpg",
        occupationSub: "",
        school: "School of Information Systems",
        university: "QUT",
        orderNumber: 5,
        email: "alistair.barros@qut.edu.au",
        homepage: "https://www.qut.edu.au/about/our-people/academic-profiles/alistair.barros",
        linkedIn: "https://www.linkedin.com/in/alistairbarros/"
    },
    {
        id: "c-5",
        selected: true,
        name: "Prof. Margot Brereton",
        occupation: OccupationSection.Collaborators,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1653014437/Margot-brereton-small_gvcgw2.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Margot.jpg",
        occupationSub: "",
        school: "School of Computer Science",
        university: "QUT",
        orderNumber: 3,
        email: "m.brereton@qut.edu.au",
        homepage: "https://www.qut.edu.au/about/our-people/academic-profiles/m.brereton",
        linkedIn: "https://www.linkedin.com/in/margot-brereton-130015a"
    },

    // HDRStudents
    {
        id: "7",
        selected: true,
        name: "Mythreyi\n Velmurugan",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535615/v2pxj7ommlm6a4uccuhp.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Mythreyi.jpg",
        occupationSub: "Sessional Academic",
        school: "School of Information Systems",
        university: "QUT",
        orderNumber: 2,
        email: "mythreyi.velmurugan@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/mythreyivelmurugan",
        scholar: "https://scholar.google.com/citations?user=MfkWJ84AAAAJ",
        homepage: "",
        github: "",
    },
    {
        id: "8",
        selected: true,
        name: "Yu-Liang Chou\n (Leon)",
        occupation: OccupationSection.Alumni,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535686/lolnxfe64mt8vydthdlk.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Leon.jpg",
        occupationSub: "MPhil Student",
        orderNumber: 3,
        email: "", // "yuliang.chou@hdr.qut.edu.au"
        linkedIn: "https://www.linkedin.com/in/leon-chou-3b56ba175",
        scholar: "",
        homepage: "",
        github: "",
        school: "", // "School of Information Systems",
        university: "", // "QUT",
    },
    {
        id: "9",
        selected: false,
        name: "Peng Yu\n (Kenny)",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535769/cbyv9gnmzbzlc5hmoc3s.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/PengYu.jpg",
        occupationSub: "PhD Student",
        orderNumber: 4,
        email: "p6.yu@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/kenny-yu-b82373130",
        scholar: "",
        homepage: "",
        github: "",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "10",
        selected: true,
        name: "Bemali\n Wickramanayake",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619535852/efc2p3t0h1soojoksmdi.png",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Bemali.png",
        occupationSub: "PhD Student",
        orderNumber: 5,
        email: "bemali.wickramanayake@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/bemali-wickramanayake-b2627261",
        scholar: "",
        homepage: "",
        github: "",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "11",
        selected: true,
        name: "Jia Wei\n (Jenny)",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1620277624/rx31uwskj5lh26vpf8ws.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Jenny.jpg",
        occupationSub: "PhD Student",
        orderNumber: 6,
        email: "jia.wei@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/jia-jenny-wei-203795132/",
        scholar: "",
        homepage: "",
        github: "",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "12",
        selected: true,
        name: 'Chihcheng Hsieh\n (Richard)',
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1619954466/frip22rpmow3rmas33lg.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Richard.jpg",
        occupationSub: "PhD Student",
        orderNumber: 7,
        email: "c21.hsieh@qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/chihcheng-hsieh-04623989",
        scholar: "",
        homepage: "",
        github: "https://github.com/ChihchengHsieh",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "13",
        selected: true,
        name: "Zhipeng He\n (Zippo) ",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/dcd56w8gc/image/upload/v1621732433/hb4j78tkbsewdqnhdvzw.jpg",
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1652426914/20220513_071031355_iOS_e4dceo.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Zippo.jpg",
        occupationSub: "PhD Student",
        orderNumber: 8,
        email: "zhipeng.he@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/zhipenghe",
        homepage: "https://zhipenghe.me",
        github: "https://github.com/ZhipengHe",
        scholar: "",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "14",
        selected: true,
        name: "Jing Yang\n (Roy)",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633505346/avatar_dr4ypx.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Roy.jpg",
        occupationSub: "Post-doc Research Fellow",
        orderNumber: 1,
        email: "roy.j.yang@qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/roy-jingyang",
        scholar: "https://scholar.google.com/citations?user=Ms1FNhsAAAAJ",
        homepage: "https://royjy.me/",
        github: "https://github.com/roy-jingyang",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "15",
        selected: false,
        name: "Giuseppe\n Jordão",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633574090/GetAttachmentThumbnail_ihcpdc.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Giuseppe.jpg",
        occupationSub: "Research Assistant",
        orderNumber: 9,
        email: "guiseppe.jordao@qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/giuseppe-jord%C3%A3o-5b6006120",
        homepage: "",
        github: "",
        scholar: "",
        school: "School of Information Systems",
        university: "QUT",
    },
    {
        id: "19",
        selected: true,
        name: "Zeping Wang\n (Chester)",
        occupation: OccupationSection.HDRStudents,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1664861923/chester_el3c5g.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Chester.jpg",
        occupationSub: "PhD Studnet",
        orderNumber: 10,
        email: "zeping.wang@hdr.qut.edu.au",
        linkedIn: "https://www.linkedin.com/in/zeping-wang/",
        school: "School of Information Systems",
        university: "QUT", 
    },

    // ExternalResearchers
    {
        id: "16",
        selected: true,
        name: "Gonçalo Almeida",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1633680762/GetAttachmentThumbnail_zxf6mt.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Goncalo.jpg",
        orderNumber: 2,
        occupationSub: "Master Student",
        linkedIn: "https://www.linkedin.com/in/goncalo-h-almeida",
        homepage: "",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon",
    },
    {
        id: "17",
        selected: true,
        name: "André Luís",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/v1634702772/andre.luis_xl4yu2.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Andre.jpg",
        orderNumber: 3,
        occupationSub: "Master Student",
        email: "andre.t.luis@tecnico.ulisboa.pt",
        linkedIn: "",
        homepage: "",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon",
    },
    {
        id: "18",
        selected: true,
        name: "Diogo Alvito",
        occupation: OccupationSection.ExternalResearchers,
        // profileImage: "https://res.cloudinary.com/zhipenghe/image/upload/c_crop,g_face,h_822,w_666/v1636075375/Diogo_wbcwq6.jpg",
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/Diogo.jpg",
        orderNumber: 4,
        occupationSub: "Master Student",
        email: "",
        linkedIn: "https://www.linkedin.com/in/diogoalvito",
        homepage: "",
        github: "",
        scholar: "",
        school: "Instituto Superior Técnico",
        university: "University of Lisbon",
    },
    {
        id: "20",
        selected: true,
        name: "Alexander Stevens",
        occupation: OccupationSection.ExternalResearchers,
        profileImage: "https://cdn.jsdelivr.net/gh/ZhipengHe/ImgRepo@master/img/alex.jpg",
        orderNumber: 1,
        occupationSub: "Visiting PhD Student @QUT",
        email: "alexander.stevens@kuleuven.be",
        linkedIn: "https://www.linkedin.com/in/alexander-stevens-354b41183/",
        homepage: "https://alexanderpaulstevens.github.io/portfolio/",
        scholar: "https://scholar.google.com/citations?user=fNeFT5EAAAAJ",
        school: "PhD Researcher",
        university: "KU Leuven",

    }
];
